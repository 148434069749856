import React from 'react';
import styled from 'styled-components';
import {darken} from 'polished';
import Link from 'next/link';
import {HeadingS} from '../../Typography';
import {IconCalendar} from '../../Icons';
import {format} from 'date-fns';
import {it} from 'date-fns/locale';

interface WebinarBannerProps {
  webinar: Webinar;
  store: Store;
}

export default function WebinarBanner({webinar, store}: WebinarBannerProps) {
  return (
    <WebinarBannerContainer>
      <WebinarLeft>
        <HeadingS>{webinar.title}</HeadingS>
        <div dangerouslySetInnerHTML={{__html: webinar.description}}></div>
      </WebinarLeft>
      <WebinarRight>
        <WebinarDate>
          <IconCalendar size="24px" fill="#fff" />
          {format(new Date(webinar.date), 'EEEE d MMMM yyyy - HH:mm', {
            locale: it,
          })}
        </WebinarDate>
        <WebinarLink>
          <Link href={`/${store.slug}/webinar/${webinar.id}`}>Iscriviti</Link>
        </WebinarLink>
      </WebinarRight>
    </WebinarBannerContainer>
  );
}

const WebinarBannerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  padding: ${(props) => props.theme.layout.frontendSpace};
  margin-bottom: ${(props) => props.theme.layout.frontendSpace};
  border-radius: 5px;
  border: 3px solid #fff;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.main} 0%,
    ${(props) => darken(0.15, props.theme.colors.main)} 100%
  );
`;

const WebinarLeft = styled.div`
  flex: 0 0 65%;
  @media ${(props) => props.theme.responsive.phone},
    ${(props) => props.theme.responsive.desktopSmall} {
    flex: 0 0 100%;
  }

  div {
    font-size: 14px;
    line-height: 1.4;
  }

  p,
  li,
  a {
    color: #fff;
    font-size: 14px;
    line-height: 1.4;
  }
`;
const WebinarRight = styled.div`
  flex: 0 0 33%;
  @media ${(props) => props.theme.responsive.phone},
    ${(props) => props.theme.responsive.desktopSmall} {
    flex: 0 0 100%;
    margin-top: ${(props) => props.theme.layout.frontendSpace};
  }
`;
const WebinarDate = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 15px;

  @media ${(props) => props.theme.responsive.phone} {
    justify-content: flex-start;
  }

  svg {
    margin-right: 10px;
  }
`;

const WebinarLink = styled.div`
  a {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 12px;
    height: 100%;
    padding: 10px ${(props) => props.theme.layout.frontendSpace};
    background-color: #fff;
    color: ${(props) => props.theme.colors.main};
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 400ms, color 400ms, box-shadow 400ms;

    &:hover {
      background-color: ${(props) => props.theme.colors.main};
      color: #fff;
      box-shadow: 0px 0px 12px ${(props) => props.theme.colors.shadow};
    }
  }
`;
