import React from 'react';
import Legal from '../Legal';
import Header from '../Lobby/Header';
import {Main, Page, PageInside} from '../Lobby/layout';
import {HeadingM} from '../Typography';

interface StoreNotAvailableProps {
  store: Store;
  company: Company;
}

const StoreNotAvailable = ({store, company}: StoreNotAvailableProps) => {
  return (
    <Page
      style={{
        backgroundImage: store.background
          ? `url('${store.background}')`
          : 'none',
      }}
    >
      <PageInside>
        <Main>
          <Header
            logo={company.logo}
            storeName={store.name}
            storeSlug={store.slug}
            collapse={false}
          >
            <HeadingM>
              La stanza richiesta al momento non è disponibile.
            </HeadingM>
          </Header>
        </Main>
        <Legal />
      </PageInside>
    </Page>
  );
};

export default StoreNotAvailable;
