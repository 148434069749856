import React, {PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';
import Link from 'next/link';
import defaultLogo from '../../../assets/images/logo-default.svg';
import Image from 'next/image';

type Props = PropsWithChildren<{
  storeName: string;
  logo: string;
  storeSlug: string;
  collapse?: boolean;
}>;

const Header = ({children, storeName, logo, collapse, storeSlug}: Props) => {
  const logoImage = logo || defaultLogo.src;
  return (
    <Container>
      <HeaderContent $collapse={collapse}>
        <Link href={`/${storeSlug}`}>
          <Logo>
            <Image
              src={logoImage}
              alt={storeName}
              style={{objectFit: 'contain', objectPosition: 'left center'}}
              fill
            />
          </Logo>
        </Link>
        <Title>{storeName}</Title>
      </HeaderContent>
      {children && (
        <Children $collapse={collapse}>
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </Children>
      )}
    </Container>
  );
};

export default Header;

const Container = styled.header`
  z-index: 0;
  padding-top: 82px;
  position: relative;
`;

const HeaderContent = styled.div<{$collapse: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 16px 32px;
  width: 100%;
  height: 82px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 10px 25px ${(props) => props.theme.colors.shadow};
  z-index: 1;

  ${(props) =>
    props.$collapse &&
    css`
      border-radius: 5px;
    `}
`;

const Logo = styled.div`
  display: block;
  width: 200px;
  height: 50px;
  margin: 0;
  margin-right: 24px;
  cursor: pointer;
  position: relative;
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  text-align: right;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.main};
  font-family: ${(props) => props.theme.typography.main};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Children = styled.div<{$collapse: boolean}>`
  ${(props) =>
    props.$collapse &&
    css`
      display: none;
    `}
`;

const ChildrenWrapper = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0 10px 25px ${(props) => props.theme.colors.shadow};

  h1 {
    margin: 0;
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.main};
  }

  p:first-of-type {
    font-weight: 700;
    margin-bottom: 30px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;
