import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import {useUser} from '../../contexts/UserContext';
import {Youmain} from '../Icons';

const Legal = () => {
  const {user} = useUser();

  return (
    <Container>
      <Link href="https://youmain.it" target="_blank" rel="noopener noreferrer">
        <Youmain fill="#fff" />
      </Link>
      <Link href="/prova-dispositivo" target="_blank" rel="noopener noreferrer">
        Prova il tuo dispositivo
      </Link>
      <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">
        Privacy policy
      </Link>
      <Link
        href="https://youmain.it/tos"
        target="_blank"
        rel="noopener noreferrer"
      >
        Termini del servizio
      </Link>
      {user && !user.isGuest && <Link href="/admin">Area riservata</Link>}
    </Container>
  );
};

export default Legal;

const Container = styled.div`
  font-family: ${(props) => props.theme.typography.main};
  width: 100%;
  padding: 12px 0;
  border-radius: 5px;
  z-index: 1;
  margin-top: ${(props) => props.theme.layout.frontendSpace};
  @media ${(props) => props.theme.responsive.phone} {
    text-align: center;
  }

  a {
    font-size: 12px;
    color: #fff;
    margin-right: 10px;
    padding-right: 10px;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
    text-decoration: none;
    display: inline-block;
    border-right: 1px solid ${(props) => props.theme.colors.border};

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }

  svg {
    height: 15px;
    width: auto;
    display: inline-block;
    margin-bottom: -2px;
    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.5));
  }
`;
