import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import {useUser} from '../../contexts/UserContext';
import {IconCam, IconSettings, Youmain} from '../Icons';

interface AdminBarProps {
  store?: Store;
}

const AdminBar = ({store}: AdminBarProps) => {
  const {user} = useUser();

  if (!user || user.isGuest) {
    return null;
  }

  return (
    <Bar>
      <BarBlock>
        <Logo>
          <Youmain fill="#ffffff" />
        </Logo>
      </BarBlock>
      <BarBlock>
        <strong>Ciao {user.firstName}</strong>
        {store && (
          <Link href={`/admin/rooms/${store.rooms[0].id}`}>
            <span>Entra nella stanza</span>
            <IconCam fill="#ffffff" size="24px" stroke="1.7" />
          </Link>
        )}
        <Link href="/admin">
          <span>Vai al pannello</span>
          <IconSettings fill="#ffffff" size="20px" stroke="1.7" />
        </Link>
      </BarBlock>
    </Bar>
  );
};

export default AdminBar;

const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 2;
`;

const Logo = styled.div`
  svg {
    width: 90px;
    height: auto;
  }
`;

const BarBlock = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;

  > strong {
    padding: 12px;
    @media ${(props) => props.theme.responsive.phone} {
      display: none;
    }
  }

  > a {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 12px;
    text-decoration: none;
    font-size: 14px;
    height: 100%;

    svg {
      margin-left: 4px;
    }

    span {
      @media ${(props) => props.theme.responsive.phone} {
        display: none;
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;
