import styled from 'styled-components';

export const Page = styled.div`
  padding: 55px 20px 20px;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.main};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  overflow-y: auto;

  @media ${(props) => props.theme.responsive.desktop} {
    padding: 20px;
  }
  @media ${(props) => props.theme.responsive.laptop} {
    padding: 20px;
  }
  @media ${(props) => props.theme.responsive.phone} {
    padding: 20px 10px;
  }
`;

export const PageInside = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  @media ${(props) => props.theme.responsive.tablet} {
    flex-wrap: wrap;
  }
`;

export const Main = styled.div`
  margin-right: 40px;
  /* flex: 0 0 40%; */
  width: 40%;
  min-width: 40%;
  align-self: stretch;
  position: relative;

  @media ${(props) => props.theme.responsive.desktopSmall} {
    width: 60%;
    /* flex: 0 0 60%; */
  }

  @media ${(props) => props.theme.responsive.tablet} {
    margin-right: 0;
    width: 100%;
    flex: 0 0 100%;
  }
`;

export const Side = styled.div`
  width: calc(60% - 40px);
  display: block;
  min-height: 100%;
  align-self: stretch;

  @media ${(props) => props.theme.responsive.desktopSmall} {
    width: calc(40% - 40px);
  }

  @media ${(props) => props.theme.responsive.tablet} {
    width: 100%;
    margin-top: calc(${(props) => props.theme.layout.frontendSpace} * 2);
  }
`;

export const Wall = styled.div`
  display: block;
  width: 100%;

  @media ${(props) => props.theme.responsive.tablet} {
    ${(props) =>
      props.$visible &&
      `
      display: none;
    `}
  }
`;
