import React from 'react';
import styled from 'styled-components';
import WebinarBanner from './WebinarBanner';
import {isBefore} from 'date-fns';

interface WebinarsProps {
  webinars: Webinar[];
  store: Store;
}

export default function Webinars({webinars, store}: WebinarsProps) {
  const now = new Date();
  return (
    <WebinarsContainer>
      {webinars
        .filter((w) => {
          return w.visibility === 1 && isBefore(now, new Date(w.date));
        })
        .map((webinar) => (
          <WebinarBanner key={webinar.id} webinar={webinar} store={store} />
        ))}
    </WebinarsContainer>
  );
}

const WebinarsContainer = styled.div`
  display: block;
  width: 100%;
`;
