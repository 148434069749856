import React, {useMemo} from 'react';
import styled from 'styled-components';
import Block from './Blocks';
import Webinars from './Webinars';
import * as API from '../../utils/api';
import useAxios from '../../hook/useAxios';

interface WallProps {
  wall: AllBlockWidgets;
  layout: GridItems;
  store: Store;
}

export default function Wall({layout = [], wall = [], store}: WallProps) {
  const rows = useMemo(
    () => Math.max(...layout.map((a) => a.y + a.h)),
    [layout]
  );

  const {result: webinars} = useAxios(
    () => API.fetchStoreWebinars(store.id),
    []
  );

  // TODO: do the sort on save wall config
  const sortedWall = useMemo(() => {
    return wall.sort((blockA, blockB) => {
      const blockDataA = layout.find((l) => l.i === blockA.id);
      const blockDataB = layout.find((l) => l.i === blockB.id);
      if (!blockDataA) {
        return 0;
      }
      if (!blockDataB) {
        return 0;
      }
      if (blockDataA.y === blockDataB.y) {
        return blockDataA.x - blockDataB.x;
      }
      return blockDataA.y - blockDataB.y;
    });
  }, [wall, layout]);

  return (
    <>
      {webinars && webinars.length > 0 && (
        <Webinars webinars={webinars} store={store} />
      )}
      <WallContainer $rows={rows} $layout={layout} $blocks={sortedWall}>
        {sortedWall.map((block) => (
          <div key={block.id} className={`block_${block.id}`}>
            <Block block={block} />
          </div>
        ))}
      </WallContainer>
    </>
  );
}

// 200 / (800 / 3) => proportion between 1x1 row of the grid
// (h / w) => proportion of the single block

const gridItemClassGenerator = ({i, x, y, w, h}: GridItem, theme, block) => `

  .block_${i} {
    grid-area: ${y + 1} / ${x + 1} / ${y + h + 1} / ${x + w + 1};
    padding: 0 8px 16px 8px;

    @media ${theme.responsive.desktopSmall} {
      padding-bottom: ${block.type !== 'text' ? `${75 * (h / w)}` : 0}%;
      grid-area: none;
      margin-bottom: ${theme.layout.frontendSpace};
      position: relative;

      > div {
        position: ${block.type !== 'text' ? 'absolute' : 'relative'};
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
`;

// grid-template-rows explanation:
// 1920 (max-width) * 60% (larghezza colonna) - 40px (margine sinistro) * 0.25 (rapporto 800x200)

const WallContainer = styled.div<{
  $rows: number;
  $layout: GridItems;
  $blocks: AllBlockWidgets;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 -8px;
  grid-template-rows: repeat(
    ${(props) => props.$rows},
    min(278px, calc(15vw - 10px))
  );

  @media ${(props) => props.theme.responsive.desktopSmall} {
    display: block;
    grid: none;
    gap: 0;
    margin: 0;
  }

  & + & {
    margin-top: ${(props) => props.theme.layout.frontendSpace};
  }

  ${(props) =>
    props.$layout.map((item) =>
      gridItemClassGenerator(
        item,
        props.theme,
        props.$blocks.find((block) => block.id === item.i)
      )
    )}
`;
